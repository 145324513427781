import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import EyewearCollections from "../components/eyewear_collections"
import InstagramFeed from "../components/instagram_feed"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <section className="hero">
      <div className="hero-content">
        <h1>Optiek De Leersnijder</h1>
        <h2 style={{textShadow: "0px 0px 20px #fff"}}>Jouw ogen in de beste handen</h2>
        <p className="w-1/2 md:w-8/12 lg:w-1/2 my-4">Welkom bij Optiek De Leersnijder. Een optiekzaak in hartje Wuustwezel met maar één doel voor ogen: de beste oogzorg bieden voor jong en oud!</p>
        <p className="w-1/2 md:w-8/12 lg:w-1/2 my-4">Ons gedreven team van gediplomeerde opticiens, optometristen en contactlensspecialisten staat klaar om jou te ontvangen met een glimlach en advies op maat.</p>
        <p className="w-1/2 md:w-8/12 lg:w-1/2 my-4">Wij kijken verder dan alleen een mooi montuur. Bij ons zijn jouw ogen in de beste handen.</p>
        <div className="flex flex-wrap w-8/12 lg:w-1/2 mt-16 ">
          <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
          <a href="https://eyecatchonline.com/nl/" target="_blank" rel="noreferrer" title="Webshop" className="button bg-dl-green hover:bg-black mr-2 text-white">Webshop</a>
        </div>
      </div>
      <StaticImage
          src="../images/hero_2.jpg"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Optiek De Leersnijder"
          placeholder="blurred"
          className="hero-img"
      />
      <div className="hero-bg"></div>
    </section>    
    <section className="section">
      <h2 className="col-span-full text-center z-20">Eyewear</h2>
      <EyewearCollections />
    </section>
    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">Gratis oogtest</h2>
        <p>Onze ogen zijn een kostbaar goed. Om ze zo goed mogelijk te beschermen, is een oogmeting een belangrijke eerste stap.</p>
        <p>Onze gediplomeerde opticiens meten jouw ogen nauwkeurig met de revolutionaire DNEye® oogscanner. Zo hebben we in enkele ogenblikken een exact resultaat van 0.01/100 Dioptrie.</p>
        <div className="flex flex-wrap mt-10 ">
          <Link to="/diensten" title="Onze diensten ..." className="button bg-transparent text-dl-orange px-0 py-0">{"Onze diensten ->"}</Link>
        </div>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/oogmeting_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        <StaticImage
            src="../images/oogmeting_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>
    <section className="section">
        <h2 className="col-span-full text-center z-20">
          Social
        </h2>
        <h4 className="col-span-full text-center z-20">
          <a href="https://www.instagram.com/eyecatchonline/" target="_blank" rel="noreferrer" className="text-dl-orange">@eyecatchonline</a>
        </h4>
        <InstagramFeed />
        {/* <div className="section-bg-right"></div> */}
      </section>
  </Layout>
)

export default IndexPage
